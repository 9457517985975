.icon {
  max-width: 15px;
}

.experiences-list {
  .top-title {
    background-color: #f96b5624;
  }

  hr {
    background-color: #efefef;
  }

  /* Gradient overlay with CSS */
  .overlay:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent
      linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(0 0 0 / 36%) 32%,
        rgba(0, 0, 0, 0.9568627451) 59%,
        #000000 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.5;
    z-index: 300;
    border-radius: 19px;
  }

  .overlay {
    position: relative;
    display: block;
    min-height: 310px;
  }
  /* END Overlay only code */

  .experience-card-content {
    width: 100%;
    color: #ffffff !important;
    bottom: 0;
    position: absolute;
    z-index: 400;
  }

  .experience-card {
    height: 100%;
    width: 100%;
    color: #ffffff;
  }

  .view-rate-count {
    padding: 5px;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0.75;
  }

  .boxStyleIcons {
    height: 20px;
    display: grid;
  }

  .verticleLine {
    height: 100%;
    width: 0.8px;
    background-color: gray;
    margin: 0 5px;
  }

  .icon-color {
    color: #7c7878;
  }

  .search {
    position: relative;
    .input-group-left {
      border: none;
      background-color: unset;
      position: absolute;
      padding-left: 0.5rem;
    }
    .input-group-right {
      border: none;
      background-color: unset;
      position: absolute;
      padding-right: 0.5rem;
      right: 0;
      color: #7c7878;
    }
    #search {
      padding-left: 2.5rem;
      border-radius: 40px;
    }
    #search:focus {
      border-color: #f96b5624;
      box-shadow: 0 1px 1px #f96b5624 inset, 0 0 8px #f96b5624;
      outline: 0 none;
    }
  }
  .badge-item-selected {
    border: 1px solid rgb(121, 163, 255);
  }
}
.hide-mui-texfeild-border {
  fieldset {
    border: 0 !important;
  }
}
.selected-indigo {
  border: 1px solid rgb(250, 118, 47);
}

.badge-item {
  background-color: #efefef;
  border-radius: 10px;
  clear: both;
  display: inline-block;
  white-space: nowrap;
}
.badge-item-selected {
  background-color: #fc5a47;
  color: white;
  box-shadow: 0 1px 1px #5674f924 inset, 0 0 8px #567cf924;
}
.badge-item-selected-border-focus {
  box-shadow: 0 1px 1px #5674f924 inset, 0 0 8px #567cf924;
  border: 1px solid #7ca2e3;
}

.experience-details {
  .image-card {
    min-height: 210px;
    position: relative;
    border-radius: 0px 0px 15px 15px;
    .info-image-card {
      background-color: rgb(255 255 255 / 81%);
    }
  }
  .experience-description {
    background-color: #f5f6ff;
    .text-subtitle {
      color: #707070;
    }
  }
  .single-pass-card {
    // background-image: url("../images/ExperiencePageAssets/pass_card.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 150px;
  }
  .package-pass-card {
    // background-image: url("../images/ExperiencePageAssets/package_card.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 150px;
  }
}

.text-indigo {
  color: #8671df;
}

.border-right {
  border-right: 1px solid #acacac7a;
}
.scrollContainerExp {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 8px;
  scroll-behavior: smooth;
  white-space: nowrap;

  /* Hide scrollbar cross-browser */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollContainerExp::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.paxButtonExp {
  background-color: #ffffff;
  border: 1px solid #927de8;
  border-radius: 8px;
  width: fit-content !important;
  min-width: 92px;
}

.package-pass-card {
  // background-image: url("../images/ExperiencePageAssets/package_card.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 150px;
}
