.add-tip {
  border: 1px solid rgba(128, 128, 128, 0.477);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 12px 0;
}
.add-tip-dropdown {
  background-color: #c8c8f826;
}

.shadow-button {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 11px;
}

.language-popper {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 12px;
  background-color: white;
  width: 300px;
  height: 170px;
}
.language-button {
  text-transform: none;
  font-size: 16px;
  width: 120px;
  height: 40px;
  font-family: "Mulish", sans-serif;
}
.language-button.selected {
  font-weight: bold;
  border: 2px solid #ee3a23;
  background-color: #ffe5e0;
  color: #ee3a23;
  border-radius: 25px;
}
.language-button:not(.selected) {
  font-weight: normal;
  border: 2px solid #d3d3d3;
  background-color: white;
  color: #333;
  border-radius: 25px;
}
.language-button.selected:hover {
  background-color: #ffdad5;
  border-color: #ee3a23;
}
.language-button:not(.selected):hover {
  background-color: #f5f5f5;
  border-color: #bdbdbd;
}
