
.filter-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px auto;
  // justify-content: space-between;
  // width: 100%;
  // max-width: 500px;
  // margin-left: 10px;
}

.scrollable {
  overflow: scroll; /* Allow scrolling within this element */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.scrollable::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
}


.filter-button {
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  border-radius: 10px;
  // min-width: 100px; /* Unified width */
  height: 38px; /* Unified height */
  color: #333;
  cursor: pointer;
  padding: 0 15px;
  justify-content: space-between;
  font-size: 12px;
  text-align: center;
}

.filter-button:hover {
  background-color: #d1d5db; 
}

.filter-button.active-filter {
  background-color: #ef4444; 
  color: #fff; 
}

.reset-button,
.apply-button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid red; 
  border-radius: 7px;
  background-color: white; 
  color: red; 
  transition: all 0.3s ease; 
}

.reset-button {
  margin-right: 190px; 
  margin-top: 20px;
}

.reset-button:hover,
.apply-button:hover {
  background-color: red; 
  color: white; 
}

.reset-button:active,
.apply-button:active {
  background-color: red; 
  color: white; 
  border-color: red; 
}

@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
    align-items: flex-start; /* Ensures left alignment */
    text-align: left;
  }

  .section-container .d-flex {
    justify-content: flex-start !important;
  }

  .price-text {
    margin-top: -10px;
    margin-left: 10px;
  }

  .add-button-container {
    margin-top: -5px; /* Moves the Add button upwards */
  }
}


