.add-tip {
  border: 1px solid rgba(128, 128, 128, 0.477);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin: 12px 0;
}
.add-tip-dropdown {
  background-color: #c8c8f826;
}

.shadow-button {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #ffffff;
  border-radius: 11px;
}

.food-menu-combo-modal{
  .modal-content {
    border-radius: 1.5rem;
    height: 80vh;
  }
}
.switch-menu-combo-modal{
  .modal-content {
    border-radius: 0.8rem;
    height: 80vh;
  }
}
.switch-delivery-type-modal{
  .modal-content {
    border-radius: 0.8rem;
    min-height: 45vh;
  }
}
.switch-delivery-map-modal{
  .modal-content {
    border-radius: 0.8rem;
    height: 80vh;
  }
}
.pac-container {
  z-index: 99999999999 !important;
}
#outlined-basic-kitchen {
 padding-left: 10px;
 padding-right: 10px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .food-menu-combo-modal{
    .modal-content {
      border-radius: 0.8rem;
      height: 80vh;
    }
    .modal-footer {
      display: block !important
    }
  }
  .switch-menu-combo-modal{
    .modal-content {
      border-radius: 0.8rem;
      height: 80vh;
    }
    .modal-footer {
      display: block !important
    }
  }
}